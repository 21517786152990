define("backalley/pods/components/modal-drawer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EgAey0DY",
    "block": "{\"symbols\":[\"HotLoadFaIcon0vpdoppdq76m\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"modal-drawer-overlay \",[28,\"if\",[[24,[\"isOpen\"]],\"open\",\"closed\"],null]]]],[3,\"action\",[[23,0,[]],\"onCloseClick\"]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"modal-drawer-popup \",[28,\"if\",[[24,[\"isOpen\"]],\"open\",\"closed\"],null],\" \",[28,\"if\",[[24,[\"isSmall\"]],\"small\"],null]]]],[8],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"explanation\"],[8],[0,\"Click Esc to dismiss\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"head\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n      \"],[7,\"a\",false],[12,\"class\",\"close-action\"],[3,\"action\",[[23,0,[]],\"onCloseClick\"]],[8],[0,\"\\n        \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"times\"]]]],\"parameters\":[1]},null],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-drawer-container\"],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/modal-drawer/template.hbs"
    }
  });

  _exports.default = _default;
});