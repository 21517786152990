define("backalley/pods/main/made/orders/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HazWqhEw",
    "block": "{\"symbols\":[\"HotLoadPageMainOrdersEditu2u6xdd0fbc\",\"@model\"],\"statements\":[[1,[28,\"page-title\",[[23,2,[\"channel_specific\",\"order_reference\"]]],null],false],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PageMainOrdersEdit\",[23,0,[]],\"PageMainOrdersEdit\",\"PageMainOrdersEdit\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PageMainOrdersEdit\",\"PageMainOrdersEdit\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@model\",\"@order\",\"@channel\"],[[23,2,[]],[23,2,[\"order\"]],[23,0,[\"channel\"]]]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/made/orders/edit/template.hbs"
    }
  });

  _exports.default = _default;
});