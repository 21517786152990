define("backalley/pods/main/manual-adjustments/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VVZGcfDb",
    "block": "{\"symbols\":[\"HotLoadManualAdjustmentEdits9h63ozj2od\"],\"statements\":[[4,\"component\",[[28,\"hot-load\",[\"page-heading\",[23,0,[]],[24,[\"page-heading\"]],\"page-heading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"fa\"],[[23,0,[]],\"page-heading\",[24,[\"page-heading\"]],false,true,\"map-marker-alt\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.manual_adjustments\"]],{\"statements\":[[0,\"Manual Adjustments\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    Edit\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"ManualAdjustmentEdit\",[23,0,[]],\"ManualAdjustmentEdit\",\"ManualAdjustmentEdit\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"ManualAdjustmentEdit\",\"ManualAdjustmentEdit\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@adjustment\",\"@onComplete\",\"@boutiqueName\",\"@productId\",\"@order\"],[[24,[\"model\",\"adjustment\"]],[28,\"route-action\",[\"onCompleteAdjustmentUpdate\"],null],[24,[\"model\",\"adjustment\",\"boutique\",\"name\"]],[24,[\"model\",\"adjustment\",\"reservation\",\"product\"]],[24,[\"model\",\"adjustment\",\"order\"]]]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/manual-adjustments/edit/template.hbs"
    }
  });

  _exports.default = _default;
});