define("backalley/pods/main/boutique-restock-information/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ctqZj6Dp",
    "block": "{\"symbols\":[\"HotLoadBoutiqueRestockInformationForm58zkx2g3y2t\",\"HotLoadPageHeading6bsv5acrbuh\",\"@model\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PageHeading\",[23,0,[]],\"PageHeading\",\"PageHeading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PageHeading\",\"PageHeading\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[],[[\"@fa\"],[\"stopwatch\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.boutique-restock-information\"]],{\"statements\":[[0,\"\\n      Boutique restock information\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"main.boutique-restock-information.edit\",[23,3,[]]]],{\"statements\":[[0,\"\\n      Edit\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\\n\"],[1,[28,\"page-title\",[[23,3,[\"_id\"]]],null],false],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"BoutiqueRestockInformationForm\",[23,0,[]],\"BoutiqueRestockInformationForm\",\"BoutiqueRestockInformationForm\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"BoutiqueRestockInformationForm\",\"BoutiqueRestockInformationForm\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@boutiqueRestockInformation\",\"@isEdit\"],[[23,3,[]],true]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/boutique-restock-information/edit/template.hbs"
    }
  });

  _exports.default = _default;
});