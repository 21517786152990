define("backalley/pods/main/shipment-zones/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dUYUg5Dz",
    "block": "{\"symbols\":[\"HotLoadShipmentZoneFormi1ym2qwxqj\",\"HotLoadPageHeadingfai72hv2spp\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PageHeading\",[23,0,[]],\"PageHeading\",\"PageHeading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PageHeading\",\"PageHeading\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[],[[\"@fa\"],[\"cubes\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.shipment-zones\"]],{\"statements\":[[0,\"Shipment zones\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    Edit shipment zone\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"Shipment zones\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[[28,\"concat\",[[24,[\"model\",\"source\"]],\" > \",[24,[\"model\",\"destination\"]]],null]],null],false],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"ShipmentZoneForm\",[23,0,[]],\"ShipmentZoneForm\",\"ShipmentZoneForm\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"ShipmentZoneForm\",\"ShipmentZoneForm\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@shipmentZone\",\"@isEdit\",\"@onSave\"],[[23,0,[\"model\"]],true,[28,\"route-action\",[\"save\"],null]]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/shipment-zones/edit/template.hbs"
    }
  });

  _exports.default = _default;
});