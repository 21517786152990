define("backalley/pods/main/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+OavUQ3U",
    "block": "{\"symbols\":[\"HotLoadSiteMenu9zsxpnxgzjg\"],\"statements\":[[1,[28,\"component\",[[28,\"hot-load\",[\"site-header\",[23,0,[]],[24,[\"site-header\"]],\"site-header\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"site-header\",[24,[\"site-header\"]],false,false]]],false],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"SiteMenu\",[23,0,[]],\"SiteMenu\",\"SiteMenu\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"SiteMenu\",\"SiteMenu\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@loading\",\"@user\",\"@logout\"],[[24,[\"model\",\"loading\"]],[24,[\"model\",\"user\"]],[28,\"route-action\",[\"logout\"],null]]]]],\"parameters\":[1]},null],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"site-main\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"id\",\"site-overlay\"],[10,\"class\",\"site-overlay\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/template.hbs"
    }
  });

  _exports.default = _default;
});