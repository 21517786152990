define("backalley/pods/components/packaging-category-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ey9k5WyO",
    "block": "{\"symbols\":[\"HotLoadFormSubmit5jot3nch64g\",\"HotLoadFormTextnhx0h8u4e0h\",\"@category\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FormText\",[23,0,[]],\"FormText\",\"FormText\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FormText\",\"FormText\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[],[[\"@value\",\"@action\"],[[23,3,[\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,3,[\"name\"]]],null]],null]]]]],\"parameters\":[2]},null],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n  \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FormSubmit\",[23,0,[]],\"FormSubmit\",\"FormSubmit\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FormSubmit\",\"FormSubmit\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@action\"],[[28,\"action\",[[23,0,[]],\"save\"],null]]],{\"statements\":[[0,\"\\n    Save category\\n  \"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/packaging-category-form/template.hbs"
    }
  });

  _exports.default = _default;
});